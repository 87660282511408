import React from "react";



function BlockRoofing() {

    {
        // Cargar el script de Owens Corning de forma asíncrona
        const script = document.createElement("script");
        script.src = "https://apis.owenscorning.com/client/widget.js";
        script.async = true;
        document.body.appendChild(script);
      }

  return (

<div>
<div className="oc_shingle_view"
        data-shingle="trudefinition-duration"
        data-view="house"
        data-layout="row"
        data-style="default"
      ></div>
</div>


  );
}

export default BlockRoofing;
